/* ==================================================
Copyright year:

Updates copyright year with JS
===================================================*/
var currentYear  = new Date().getFullYear();
$('#copyright').text(currentYear);


/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
var $mainNav = $('.offscreen-nav');
var $navToggle = $('.nav-toggle');
var $body = $('body');
var navState = 'closed';

$navToggle.click(function(e) {
    e.preventDefault();

    if(navState === 'closed') {
      openNav();
    } else {
      closeNav();
    }
});

function closeNav() {
  $navToggle.removeClass('active');
  $body.removeClass('nav-overlay');
  $mainNav.removeClass('open');
  $navToggle.children('.hamburger').removeClass('active');
  Foundation.Motion.animateOut($mainNav, 'fade-out fast');
  navState = 'closed';
}

function openNav() {
  $navToggle.addClass('active');
  $body.addClass('nav-overlay');
  $mainNav.addClass('open');
  $navToggle.children('.hamburger').addClass('active');
  Foundation.Motion.animateIn($mainNav, 'fade-in fast');
  navState = 'open';
}


/* ==================================================
Mobile nav display dropdown:

Toggles animated display of dropdown on mobile nav
===================================================*/

var $mobileDropDownTrigger = $('.offscreen-nav .has-dropdown');

$mobileDropDownTrigger.click(function(e) {
  $(this).toggleClass('open');
});


/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1);
if (path) {
  $(`nav a[href$="${path}"]`).parent().addClass('selected');
  $(`nav a[href$="${path}"]`).parents('.dropdown').addClass('selected');
}

if(path.split('/')[0] == 'blog') {
  $('nav a[href$="/resources"]').parent().addClass('selected');
  $('nav a[href$="/boise-real-estate-blog"]').parent().addClass('selected');
}


/* ==================================================
Mobile Nav Dropdown List:

Toggles animated display of the next nav element
after clicking parent icon
===================================================*/

$('.dropdown .arrow-icon').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('open');
  const trigger = $(this).parent().next('.list');

  const $toggleNext = new Foundation.Toggler(trigger, {
    animate: 'fade-in fade-out'
  });
  $(trigger).foundation('toggle');
});


/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function (e) {
  e.preventDefault();
  const triggerId = $(this).data('toggle-trigger');
  const toggleItem = $(`#${triggerId}`);

  $(this).toggleClass('open');

  const $toggleElement = new Foundation.Toggler(toggleItem, {
    animate: 'animate-in animate-out',
  });

  toggleItem.foundation('toggle');
});


/* ==================================================
AJAX Form Submission:

Submits a BC form via AJX and then hides the form
after successful response and displays a thank
you message
===================================================*/
function ajaxWebformSubmit(options) {
  var settings = $.extend({
        formID : null,
        formWrapper: null,
        successMsg : 'Your message has been sent!',
        errorMsg : 'Sorry, looks like there was a problem. Please try again.'
    },options);

  $.ajax({
      url: $(settings.formID).attr("action") + "&JSON=1",
      data: $(settings.formID).serialize(),
      type: "POST"
    }).done(function(msg) {
      var formResponse = eval(msg);
        if (formResponse.FormProcessV2Response.success) {
          if (settings.successMsg) {
            $(settings.formWrapper).after("<div class=\"json-success\" style=\"display:none;\">" + settings.successMsg + "</div>");
          }
          $(settings.formWrapper).hide();
          $(".json-success").fadeIn(400);
        }
    }).fail(function(msg) {
      alert(settings.errorMsg + msg);
  });
}


/* ==================================================
Sliders:
Slick carousel setup and initialization
===================================================*/

$('.slider-feature-container').slick({
  adaptiveHeight: true,
  arrows: false,
  draggable: false,
  dots: false,
});

$("[data-slide-index]").click(function(e) {
  var $sliderFeatureListNavItem = $(this).data('slide-index');
  $('.slider-feature-container').slick('slickGoTo', parseInt($sliderFeatureListNavItem));
  $('.slider-feature-nav-item').removeClass('active');
  $(`.slider-feature-nav-item[data-slide-index="${$sliderFeatureListNavItem}"]`).addClass('active');
})

/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

// $('.image-link').magnificPopup({ type: 'image' });
// $('.video-link').magnificPopup({ type: 'iframe' });

/* ==================================================
Smooth Scrolling :

Smooth scroll to hash element
===================================================*/

// Select all links with hashes
$('nav a[href^="#"]').on('click',function (e) {
    e.preventDefault();
    closeNav();
    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
        window.location.hash = target;
    });
});

$('.smooth-scroll').on('click',function (e) {
    e.preventDefault();
    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
        window.location.hash = target;
    });
});

//Utilities
$(function(){
  $('html').removeClass('no-js');
});

// Init
$(document).foundation();